import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import * as vars from './../vars.js';

import { ReactSession } from 'react-client-session';

var cats = [];
for(var i=0;i<vars.pages.length;i++) {
  var page = vars.pages[i];
  if(!cats[page.category]) {
    cats[page.category]=[];
  }
  cats[page.category].push(page);
}


function Footer(props) {

 const [categories, setCategories] = useState([]);


  useEffect(() => {
    
    //console.log(cats);
    
    setCategories(cats);
    
    
  });

  function clicked(name) {



  }


  return (<>
  <div className="footer_box">

    <div className="full_footer">
      
      {Object.keys(categories).map((category, index) => {
          if(category!='false') {
            return (<div key={index} className={"list left_list_"+index}><ul>
                    <li><span className="list_head"><a className="white_link" href={(cats[category][0].name==category?cats[category][0].href:undefined)}>{category}</a></span></li>

                    {vars.pages.map((page, index) => {
                       if(page.footer && page.category == category && page.name != category && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
                        return (<li key={index}><a className="white_link" onClick={()=>clicked(page.name)} href={page.href}>{page.name}</a></li>);
                      }
                    })}
                  </ul></div>);
          }
          
        })}

      
      <div className="footer_address">
      <img src="/logo_full_white.png" width="120" /><br/><br/>
      {vars.address_lines[0]}<br/>
      {vars.address_lines[1]}<br/>
      {vars.address_lines[2]}<br/><br/>
      <span className="footer_phone"><a className="white_link" href={"tel://"+vars.business_phone.tel}>{vars.business_phone.display}</a></span>
      <span className="white_link footer_email"><br/><a href={"mailto:"+vars.business_email}>{vars.business_email}</a></span>
      </div>
    </div>
  

    <div className="mobile_footer">

      <img style={{cursor: 'pointer'}} src="/logo_full_white.png" onClick={()=>{window.open("/", '_self')}} width="150" /><br/><br/>
        


        <h5>{vars.footer_address_lines[0]}</h5>
        <h5>{vars.footer_address_lines[1]}</h5><br/>
        
        
        <h5><a className="white_link footer_phone" href={"tel://"+vars.business_phone.tel}>{vars.business_phone.display}</a></h5>
        <h5><a className="white_link footer_email" href={"mailto:"+vars.business_email}>{vars.business_email}</a></h5><br/>
        <a href="https://twitter.com/TullochCo"><img className="social_img" src="/images/twitter.png" width="35" /></a>
        <a href="https://www.facebook.com/TullochCo"><img className="social_img social_img_last" src="/images/facebook.png" width="35" /></a>
        <br/>
        <br/>

        <div className="no-print">
        {Object.keys(categories).map((category, index) => {
          if(category!='false') {
            return (<div key={index} className={"mobile_list mobile_footer_list_"+index}><ul>
                    <div className="list_head"><a className="white_link" href={(cats[category][0].name==category?cats[category][0].href:undefined)}>{category}</a></div>
                    {vars.pages.map((page, index) => {
                      if(page.footer && page.category == category && page.name != category && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
                      
                        return (<li key={index}><div className="list_item"><a className="white_link"  onClick={()=>clicked(page.name)} href={page.href}>{page.name}</a></div></li>);
                      }
                    })}
                  <div className="list_head"></div></ul></div>);
          }
          
        })}

        
        </div>

    

    </div>
  </div>

  <div className="copyright"></div>
  <br/><br/>

  </>);

}
  
export default Footer;