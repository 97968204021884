import React, {useState, useEffect} from 'react';


import TullochForm from './components/TullochForm';


import Header from './components/Header';
import Footer from './components/Footer';

import Message from './components/Message';

import Home from './components/Home';
import About from './components/About';
import Pricing from './components/Pricing';
import Services from './components/Services';
import Contact from './components/Contact';

import * as vars from './vars.js';
import * as funcs from './funcs.js';

import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";



import { ReactSession } from 'react-client-session';

var doOnce=false;

function App() {


  const [render, setRender] = useState(0);


 


  useEffect(() => {

  //console.log('window.location.search: '+window.location.search);

    (async function() {

      if(!doOnce) {
        doOnce=true;

       
        setRender(render+1);

      }
    })();

    
    
  });



  return (
    <>
      
      <BrowserRouter>
        <Routes>
          <Route path="/message/:obj" element={<><Header headerMode="0" /><Message /><Footer /></>} />
          <Route path="/" element={<><Header headerMode="0" /><Home /><Footer /></>} />
          <Route path="/home" element={<><Header headerMode="0" /><Home /><Footer /></>} />
          <Route path="/about" element={<><Header headerMode="0" /><About /><Footer /></>} />
          <Route path="/services" element={<><Header headerMode="1" /><Services /><Footer /></>} />
          <Route path="/pricing" element={<><Header headerMode="1" /><Pricing /><Footer /></>} />
          <Route path="/contact" element={<><Header headerMode="0" /><Contact /><Footer /></>} />
        </Routes>
      </BrowserRouter>
      
      </>
  );


  
}

export default App;
