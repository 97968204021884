import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';


function Services(props) {

  const [linkStyle, setLinkStyle] = useState({textDecoration: 'none'});
  const [linkStyle2, setLinkStyle2] = useState({textDecoration: 'none'});
  const [linkStyle3, setLinkStyle3] = useState({textDecoration: 'none'});

  
  useEffect(() => {




  });

  

  return (

    <>

    <div className="patterned_blue_block">
    <div className="page_regular">
      <h1>Pricing</h1>
    </div>
  </div>
    


    <div className="patterned_white_block">
      <div className="center_block_content">

      <div className="grey_left_svg">
        <h4>No-Contract, Hourly Support</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>General IT services are billed at <b>$99/hr</b>. Remote support has a minimum billing increment of 15 minutes, and in-person visits require a minimum of 1 hour.</p>
      <h6>*Some services are subject to additional costs. Eg. Hardware purchasing, software licensing, email accounts, data backups, website hosting, etc.</h6>
      </span><br/>

      <div className="grey_left_svg">
        <h4>Unlimited Monthly Support</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>Receive unlimited monthly support for <b>$99/month per workstation user</b>, with in-person visits incurring an additional <b>$35/visit</b> for travel.</p>
      <ul>
        <li>General computer assistance</li>
        <li>Computer hardware installation and setup.</li>
        <li>Software and driver installation.</li>
        <li>Operating system and software updates</li>
        <li>Peripheral installation and service, (Eg. Print/Copy/Fax)</li>
        <li>Remote Access Solution</li>
        <li>Anti-virus Protection and Recovery</li>
        <li>Server Installation and Support</li>
        <li>Email and Website changes</li>

      </ul>
      <h6>*Some services are subject to additional costs. Eg. Hardware purchasing, software licensing, email accounts, data backups, website hosting, etc.</h6>
      </span><br/>

      <div className="grey_left_svg">
        <h4>Off-site Backups</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>Off-site backups for <b>$0.35 per Gigabyte of data</b> stored.</p>
      </span><br/>

      <div className="grey_left_svg">
        <h4>Email Accounts</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>POP/IMAP Email, Contacts and Calendars with Webmail access for <b>$29/month per account</b>.</p>
      <p>Make your business look more professional by using a custom domain for your email accounts.</p>
      </span><br/>

      </div>
    </div>



      </>


   );

}
  
export default Services;