import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';

import { motion, useAnimate } from "framer-motion"



function About(props) {



  
  useEffect(() => {




  });

  

  return (

    <>
    <div className="prairie_server">

      <div className="page">

        <div className="prairie_server_blurb">
          <h1>A Saskatchewan IT Services Company.</h1>
        </div>
      
        <div className="page_label_blurb">
          <h1>About</h1>
        </div>



      </div>

      
    </div>

    <div className="patterned_blue_block">
      <div className="center_block_content">

      <br/>

      <div className="dark_blue_left_svg">
        <h3>Serving for over a Decade</h3>
      </div><div className="dark_blue_right_svg"></div>
      <span className="left_indent">
      <p>Tulloch Co. has been providing small and medium businesses with IT services in the Regina area for over a decade.</p>
      </span><br/>

      <div className="dark_blue_left_svg">
        <h3>Affordable Technology Partner</h3>
      </div><div className="dark_blue_right_svg"></div>
      <span className="left_indent">
      <p>We provide an affordable alternative to in-house IT staff for small or medium businesses that would like to focus their energy elsewhere.</p>
      </span><br/>

      <div className="dark_blue_left_svg">
        <h3>Mastering Industry Technology</h3>
      </div><div className="dark_blue_right_svg"></div>
      <span className="left_indent">
      <p>We excel at working with a wide array of vendors and products.</p>
      <p>Whether it's industry-specific server software, CNC fabrication machines, or a workplace with mixed Mac and Windows workstations; we have relevant experience, and we're always open to new challenges.</p>
      <p>We want to master the technology used in your industry.</p>
      </span><br/>

      <div className="dark_blue_left_svg">
        <h3>About the Founder</h3>
      </div><div className="dark_blue_right_svg"></div>
      <span className="left_indent">
      <p>Colin Tulloch has been helping Regina and area businesses with their computer technology since 2008.</p>
      </span>


      </div>
    </div>



      </>


   );

}
  
export default About;