import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import * as vars from './../vars.js';

import { ReactSession } from 'react-client-session';

var menuShown=false;

function Header(props) {

  const [showOrHideMM, setShowOrHideMM] = useState({});
  const [render, setRender] = useState(0);


  

  window.addEventListener('resize', function(event) {
    if(menuShown) {
      setShowOrHideMM({display: "none"});
      menuShown=false;
    }
    //console.log('Resized? '+menuShown);
  }, true);

  function showMenu() {
    if(menuShown==false) {
      setShowOrHideMM({display: "inline-block"});
      menuShown=true;
    } else {

      setShowOrHideMM({display: "none"});
      menuShown=false;
    }
    
    //console.log('shown> '+menuShown);
  }



  function clicked(name) {



  }


  return (<>
  <div className="header">
    <div className="logo">
      <img src={props.headerMode==0?"/logo_full_black.png":"/logo_full_white.png"} height="70" onClick={()=>{window.open("/", "_self");}} />
    </div>
    <div className="header_right">
        <div className="text_header_items">
          {vars.pages.map((page, index) => {
            if(page.header && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
              return (<a key={index} onClick={()=>clicked(page.name)} href={page.href} className="black_link"><span className="header_menu_item"><img className={props.headerMode==0?"header_menu_img":"header_menu_img_light"} src={"/images/icons/"+page.name.toLowerCase()+".png"} width="20" /><div className={props.headerMode==0?"header_menu_txt":"header_menu_txt_light"}>{page.name}</div></span></a>);
            }
          })}
        </div>
        
        
      </div>
      <div className={props.headerMode==0?"hamburger_icon":"hamburger_icon_light"} onClick={showMenu}>

        </div>
  </div>
  <div className={props.headerMode==0?"mobile_menu":"mobile_menu_light"} style={showOrHideMM}>
        {vars.pages.map((page, index) => {
            if(page.header && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
              return (
                <div key={index} className={props.headerMode==0?"mobile_menu_li":"mobile_menu_li_light"}>
                  <a href={page.href} ><img className="header_mobile_menu_img" src={"/images/icons/"+page.name.toLowerCase()+".png"} width="30" /><div className="mobile_menu_li_txt">{page.name}</div></a>
                </div>
              );
            }
          })}
        </div>
  </>);

}
  
export default Header;