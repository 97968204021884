import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';

import TullochForm from './../components/TullochForm';

import { ReactSession } from 'react-client-session';


const AnyReactComponent = ({ text }) => <div>{text}</div>;
const google = window.google;
function Contact(props) {


  ReactSession.setStoreType("localStorage");
  useEffect(() => {


    // Initialize and add the map
    let map;

    async function initMap() {
      const position = { lat: 51.31089871879199, lng: -104.99623743814227 };
      // Request needed libraries.
      //@ts-ignore
      const { Map } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

      // The map, centered at Uluru
      map = new Map(document.getElementById("map"), {
        zoom: 7,
        center: position,
        mapId: "TullochCo_Map",
      });

      // The marker, positioned at Uluru
      const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        title: "Tulloch-co Map",
      });
    }

    initMap();



  });

  

  return (<>

  <div className="patterned_white_block">
    <div className="page_regular">
      <h1>Contact</h1>
    </div>
  </div>

  <div className="patterned_black_block">
    <div className="center_block_content">

   
     <div className="left_address">
      <h2>Mailing Address</h2><br/>
      <h3>Tulloch Company</h3>
      <h5>{vars.address_lines[0]}</h5>
      <h5>{vars.address_lines[1]}</h5>
      <h5>{vars.address_lines[2]}</h5><br/>
      <h5>Phone <a className="white_link" href={"tel://"+vars.business_phone.tel}><b>{vars.business_phone.display}</b></a></h5>
      <h5>Email <a className="white_link" href={"mailto:"+vars.business_email}><b>{vars.business_email}</b></a></h5>

       </div>

        <div className="right_map">

      <div className="left_svg_full">
        <h3>Located in Govan, SK</h3>
      </div>

      

      
      <div id="map"></div><br/>

    </div>

    </div>
  </div>

  <div className="patterned_white_block">
      <div className="center_block_content">

      <div className="blue_left_svg">
        <h2>Send us a Message</h2>
      </div><div className="blue_right_svg"></div>

      <TullochForm theme="dark" 
          nextReason=""
          submitLabel="Send" submitAction={"/contact/send_message"}
          backLabel="" backAction="" 
          buttonSize="lg" 
          fields={[
            { type: 'text', name: 'name', label: 'Your Name',  placeholder: 'John', defaultValue: '', readOnly: false},
            { type: 'email', name: 'email_address', label: 'Email Address',  placeholder: 'john.smith@gmail.com', defaultValue: '', readOnly: false},
            { type: 'textarea', name: 'the_message', label: 'Message',  placeholder: '', defaultValue: '', readOnly: false, textareaArgs: {rows: 3}},
            { type: 'hidden', name: 'redirect_url', defaultValue: ReactSession.get("next_url"), readOnly: true}
          ]} />

      </div>
    </div>

  </>);

}
  
export default Contact;