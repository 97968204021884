

const pages = [
  {name: "Home", category: 'General', href:'/', header: false, footer: false, logged_in_only: false, logged_out_only: false},
  {name: "About", category: 'General', href:'/about', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Services", category: 'General', href:'/services', header: true, footer: true, logged_in_only: false, logged_out_only: false},
  {name: "Pricing", category: 'General', href:'/pricing', header: true, footer: true, logged_in_only: false, logged_out_only: false},

  {name: "Contact", category: 'General', href:'/contact', header: true, footer: true, logged_in_only: false, logged_out_only: false},
];






var apiUrl = 'https://api.tulloch.co';


var address_lines = [
  "121 Elgin Street - PO Box 8",
  "Govan, SK",
  "S0G 1Z0"
];

var footer_address_lines = [
  "121 Elgin Street - PO Box 8",
  "Govan, SK - SOG 1Z0"
];

var business_phone = {
  display: '306.700.3399',
  tel: '1-(306)-700-3399'
};

var business_email = 'info@tulloch.co';


export {
  pages,
  apiUrl, 
  address_lines,
  footer_address_lines,
  business_phone,
  business_email
}