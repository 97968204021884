import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';

import { motion, useAnimate } from "framer-motion"



function Services(props) {

  const [linkStyle, setLinkStyle] = useState({textDecoration: 'none'});
  const [linkStyle2, setLinkStyle2] = useState({textDecoration: 'none'});
  const [linkStyle3, setLinkStyle3] = useState({textDecoration: 'none'});

  
  useEffect(() => {




  });

  

  return (

    <>

    <div className="data_center">

      <div className="page">

      
      <div className="page_label_blurb">
          <h1>Services</h1>
        </div>



      </div>

      
    </div>
    


    <div className="patterned_white_block">
      <div className="center_block_content">

      <div className="grey_left_svg">
        <h4>Computer and User Support</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>We offer <b>24/7/365</b> support to your computer devices, employees and workstations. Remote and in-person.</p>
      </span><br/>

      <div className="grey_left_svg">
        <h4>Managed IT Services Provider</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p><b>Avoid having to staff a full-time IT person.</b> We manage your IT services without limition. This includes your devices and workstations, industry-specific desktop and server software, cloud services, email, websites, security cameras, VOIP phones, door access systems and more.</p>
      </span><br/>

      <div className="grey_left_svg">
        <h4>Data Backups</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>Let us secure your data by implementing redundant storage, monitoring and maintaining your storage hardware, and last but not least; <b>automated off-site backups</b>.</p>
      </span><br/>

      <div className="grey_left_svg">
        <h4>Websites, Email and Apps</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>If you wish, we’ll build your company an identity, online and mobile presence. Our talented designers and software developers have decades of experience building websites, apps and desktop software.</p>
      </span><br/>

      <div className="grey_left_svg">
        <h4>Online Media and Advertising</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>We can help you craft targeted, goal-driven online advertising campaigns for your business, in addition to providing search engine optimization for your website.</p>
      </span><br/>

      <div className="grey_left_svg">
        <h4>Security and Anti-virus</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>We can help you harden your network and devices against modern online threats including viruses, ransomware, email attacks and more.</p>
      <p>There's no substitute for properly trained employees and online awareness, but we'll do everything we can in order to provide the safest environment possible for dealing with these dangers.</p>
      </span><br/>

      <div className="grey_left_svg">
        <h4>Network, VOIP and More</h4>
      </div><div className="grey_right_svg"></div>
      <span className="left_indent">
      <p>In addition to wiring, configuring and managing your network environment; we offer a complete VOIP phone solution, security cameras, and building access card/keyfob/code systems.</p>
      </span><br/>


      </div>
    </div>



      </>


   );

}
  
export default Services;