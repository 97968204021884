import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';

import { motion, useAnimate } from "framer-motion"



function Home(props) {

  const [linkStyle, setLinkStyle] = useState({textDecoration: 'none'});
  const [linkStyle2, setLinkStyle2] = useState({textDecoration: 'none'});
  const [linkStyle3, setLinkStyle3] = useState({textDecoration: 'none'});

  
  useEffect(() => {




  });

  

  return (

    <>
    <div className="prairie_server">

      <div className="page">

        <div className="prairie_server_blurb">
          <h1>A Saskatchewan IT Services Company.</h1>
        </div>
      
        <div className="page_label_blurb">
          
        </div>



      </div>

      
    </div>

    <div className="patterned_blue_block">
      <div className="center_block_content">

      

      

      

      <div className="third" 
      onClick={()=>window.open('/services', '_self')} 
      onMouseOver={()=>setLinkStyle({textDecoration: 'underline'})} 
      onMouseOut={()=>setLinkStyle({textDecoration: 'none'})}>
      <img className="home_link_icon" src="/images/icons/services.png" width="50"  /><br/>
      <h4><a style={linkStyle} className="home_link">Explore our Services</a></h4>
      </div>

      <div className="third" 
      onClick={()=>window.open('/contact', '_self')} 
      onMouseOver={()=>setLinkStyle2({textDecoration: 'underline'})} 
      onMouseOut={()=>setLinkStyle2({textDecoration: 'none'})}>
      <img src="/images/icons/contact.png" width="50" /><br/>
      <h4><a style={linkStyle2} className="home_link">Get in Touch</a></h4>
      </div>



      <div className="last_third"
      onClick={()=>window.open('/about', '_self')} 
      onMouseOver={()=>setLinkStyle3({textDecoration: 'underline'})} 
      onMouseOut={()=>setLinkStyle3({textDecoration: 'none'})}>
      
      
      <img src="/images/icons/about.png" width="50" /><br/>
      <h4><a style={linkStyle3} className="home_link">About the Company</a></h4>

      </div>



      </div>
    </div>



      </>


   );

}
  
export default Home;